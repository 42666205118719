// Sticky Footer
html {
    height: 100%;
}
body {
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
}
footer {
    grid-row-start: 2;
    grid-row-end: 3;
    border-top: 2px solid #e4e4e4;
    border-bottom: 4px solid $primary;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE Fix
    html {
        display: flex;
    }
      
    body {
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
      
    footer {
        flex-shrink: 0;
        background-color: #F8FAFC;
    }
      
    #app {
        flex-grow: 1;
    }
}