.va-home {
    background-image: url('/images/bg.jpg');
    background-repeat: repeat;
    background-size: cover;
    color: white;

    .va-videos {
        h3 {
            border-top: 1px solid #ffffff40;
            padding-top: 2rem;
            margin-top: 2rem;
        }
        a.va-video {
            color: white;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}